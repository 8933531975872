import React from 'react';
import Button from './Button';
import RichText from './RichText';

import { useForm } from 'react-hook-form';
import { mergeClassNames, useTranslate } from '../utils';
import { Input, registerOptions } from './Form';
import styles from './NewsletterSignup.module.css';
import { mapData, newsletterAudienceUrl, useMailchimp } from './useMailchimp';

export const NewsletterSignup = (props) => {
  const { translateToString } = useTranslate();
  const [mailchimp, subscribeToMailchimp] = useMailchimp(newsletterAudienceUrl);
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({ mode: 'all' });

  const onSubmit = (data) => {
    subscribeToMailchimp(mapData(data));
  };

  const { content } = props;

  return (
    <section
      id="newsletter-signup"
      className={mergeClassNames(
        'section',
        styles.root,
        content.hasBackgroundColor && styles.background
      )}
    >
      <div className="container content">
        {content && <RichText className={styles.content} content={content} />}
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="email"
            errors={errors}
            placeholder={`${translateToString('signup.email')}*`}
            ref={register(registerOptions.email)}
          />
          <Button type="submit" disabled={!isValid}>
            {translateToString(
              `signup.status-${mailchimp.status || 'subscribe'}`
            )}
          </Button>
        </form>
      </div>
    </section>
  );
};
