import { INLINES } from '@contentful/rich-text-types';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import visibleOnce from '../hoc/visibleOnce';
import { isInternalURL, mergeClassNames } from '../utils';
import RichText from './RichText';
import styles from './TextAndLinks.module.css';

const TextAndLinks = (props) => {
  const { className, content, children, isVisible } = props;
  const renderLinks = (node, children) => {
    const { uri } = node.data;
    if (isInternalURL(uri))
      return (
        <Link className={styles.link} to={uri} state={{ internal: true }}>
          {children}
        </Link>
      );

    return (
      <a
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        href={uri}
      >
        {children}
      </a>
    );
  };

  useEffect(() => {
    if (isVisible) {
      const links = document.querySelectorAll(`.${styles.link}`);
      const linkContainer = links[0]?.parentElement;
      if (linkContainer) {
        linkContainer.className = styles.links;
      }
      setTimeout(() => {
        Array.from(links).forEach((link, i) => {
          link.style.visibility = 'visible';
          link.style.position = 'relative';
          link.style.transitionDelay = `${i * 100}ms`;
          link.style.transform = 'translateX(0)';
        });
      }, 250);
    }
  }, [isVisible]);

  return (
    <RichText
      {...props}
      className={mergeClassNames(className, styles.standardContentBlock)}
      content={content}
      renderer={{
        renderNode: {
          [INLINES.HYPERLINK]: renderLinks,
        },
      }}
    >
      {children}
    </RichText>
  );
};

export default visibleOnce(TextAndLinks, { partialVisibility: false });
