import React from 'react';

import RichText from './RichText';

import visibleOnce from '../hoc/visibleOnce';
import { mergeClassNames } from '../utils';
import ImageWithDescription from './ImageWithDescription';
import styles from './OverlappingTileBlock.module.css';

const OverlappingTileBlock = ({
  isVisible,
  className,
  content,
  attachments,
  isOdd,
  onClick,
}) => {
  const classNames = mergeClassNames(
    className,
    styles.root,
    !isOdd && styles.odd,
    isVisible && styles.visible
  );

  return (
    <section className={classNames} onClick={onClick}>
      {attachments && (
        <ImageWithDescription
          useSquared
          onClick={onClick}
          className={styles.image}
          image={attachments[0]}
        ></ImageWithDescription>
      )}
      <RichText className={styles.text} content={content}></RichText>
    </section>
  );
};

export default visibleOnce(OverlappingTileBlock);
