/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Slider from 'react-slick';
import { mergeClassNames, useTranslate } from '../utils';
import Button from './Button';
import json from './customerProfile.json';
import styles from './CustomerProfile.module.css';
import {
  AddressInput, CheckRadio, errorMessages, Input, joinCheckboxKeys, registerOptions, Select
} from './Form';
import Modal, { useModal } from './Modal';
import RichText from './RichText';
import Translate from './Translate';
import {
  customerProfileAudienceUrl,
  mapData,
  useMailchimp
} from './useMailchimp';





const getTitle = (text, replacements) => {
  return Object.entries({
    ...replacements,
    requestOrSearchText: (() => {
      if (replacements.search && replacements.offer) {
        return 'Womit können wir Ihnen helfen?';
      }
      if (replacements.offer) {
        return 'Was sollen wir für Sie bewerten?';
      }
      if (replacements.search) {
        return 'Wonach suchen Sie?';
      }
    })(),
    detailsText: () => {
      if (replacements.search && replacements.offer) {
        return 'Details';
      }
      if (replacements.offer) {
        return 'Informationen zu Ihrer Immobilie';
      }
      if (replacements.search) {
        return 'Was ist Ihnen besonders wichtig?';
      }
    },
  }).reduce(
    (acc, [key, value]) => acc.replace(new RegExp(`{{${key}}}`, 'g'), value),
    text
  );
};

const getCheckboxKeys = (pages) => {
  return pages.reduce((acc, { fields }) => {
    return [
      ...acc,
      ...fields
        .filter(({ type }) => type === 'checkbox')
        .map(({ name }) => name),
    ];
  }, []);
};

const mainProps = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  accessibility: false,
  arrows: false,
  autoplay: false,
  dots: false,
};

const Navigation = ({ pages, setPage, currentPageIndex, disabledUntil }) => {
  const lastPageIsDisabled = disabledUntil !== pages.length;
  return (
    <ul className={styles.nav}>
      {pages.map((_, i) => {
        const isDisabled = disabledUntil < i;
        return (
          <li
            key={`navigation_${i}`}
            onClick={() => !isDisabled && setPage(i + 1)}
            className={mergeClassNames(
              i === currentPageIndex && styles.isActive,
              isDisabled && styles.isDisabled
            )}
          >
            <div />
          </li>
        );
      })}
      <li
        onClick={() => !lastPageIsDisabled && setPage(pages.length + 1)}
        className={mergeClassNames(
          currentPageIndex === pages.length && styles.isActive,
          lastPageIsDisabled && styles.isDisabled
        )}
      >
        <i className="fas fa-check"></i>
      </li>
    </ul>
  );
};

const renderFields = ({ fields, register, errors, getValues }) => {
  return fields.map(({ choices, isGrid, ...field }) => {
    const options = registerOptions[field.name] || registerOptions[field.type];
    const isRadio = field.type === 'radio';
    const placeholder = options?.required
      ? `${field.placeholder}*`
      : field.placeholder;

    switch (field.type) {
      case 'address':
        return (
          <AddressInput
            {...field}
            key={field.name}
            placeholder={placeholder}
            errors={errors}
            ref={register(options)}
          />
        );
      case 'input':
      case 'number':
        return (
          <Input
            {...field}
            key={field.name}
            placeholder={placeholder}
            errors={errors}
            ref={register(options)}
          />
        );
      case 'checkbox':
      case 'radio':
        return (
          <div
            className={mergeClassNames(
              'field',
              styles.field,
              isGrid && styles[isGrid]
            )}
            key={field.name}
          >
            <div className={mergeClassNames('control', styles.control)}>
              {choices.map((choice, i) => {
                const isBooleanField = choices.length === 1;
                const name =
                  isRadio || isBooleanField ? field.name : `${field.name}_${i}`;
                return (
                  <CheckRadio
                    {...field}
                    key={`${field.name}_${i}`}
                    name={name}
                    label={choice}
                    value={isBooleanField ? true : choice}
                    errors={errors}
                    ref={register(options?.(field, getValues) || options)}
                  />
                );
              })}
            </div>
          </div>
        );
      case 'select':
        return (
          <Select
            {...field}
            key={field.name}
            placeholder={placeholder}
            options={choices}
            errors={errors}
            ref={register(options)}
          />
        );
      default:
        return null;
    }
  });
};

const Page = ({ fields, onValid, isGrid }) => {
  const {
    register,
    getValues,
    formState: { isValid, errors },
    trigger,
  } = useForm({ mode: 'all' });

  useEffect(() => {
    const values = getValues();
    onValid(isValid, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, getValues]);

  return (
    <form
      className={mergeClassNames(styles.page, isGrid && styles[isGrid])}
      onMouseLeave={() => trigger()}
    >
      {renderFields({ fields, register, errors, getValues })}
    </form>
  );
};

const Card = ({
  children,
  withNavigation,
  title,
  pages,
  currentPageIndex,
  setPage,
  disabledUntil,
}) => {
  return (
    <div className={mergeClassNames('card', styles.card)}>
      <div className={mergeClassNames('card-content', styles.cardContent)}>
        <h4 className="title">{title}</h4>
        {children}
        {withNavigation && (
          <Navigation
            pages={pages}
            currentPageIndex={currentPageIndex}
            setPage={setPage}
            disabledUntil={disabledUntil}
          />
        )}
      </div>
    </div>
  );
};

export const CustomerProfile = (props) => {
  const { content } = props;
  const cardWrapper = useRef(null);
  const { translateToString } = useTranslate();
  const [pageIndex, setPageIndex] = useState(-1);
  const [validPages, setValidPages] = useState([
    ...json.pages.map((_) => false),
    false,
  ]);
  const [values, setValues] = useState({});
  const [processingModalVisible, toggleProcessingModal] = useModal();
  const [mailchimp, subscribeToMailchimp] = useMailchimp(
    customerProfileAudienceUrl
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({ mode: 'all' });

  const joined = joinCheckboxKeys(getCheckboxKeys(json.pages), {
    ...values,
    ...getValues(),
  });
  const replacements = {
    ...getValues(),
    ...values,
    ...joined,
  };

  const getMaxPage = () => {
    const firstIndex = validPages.indexOf(false);
    return firstIndex === -1 ? validPages.length : firstIndex;
  };

  const onPageSet = (page) => {
    setPageIndex(page - 1);
    cardWrapper.current?.slickGoTo(page);
  };

  useEffect(() => {
    console.log({ mailchimp });
  }, [mailchimp]);

  const onSubmit = (data) => {
    const sanitized = mapData(replacements);
    subscribeToMailchimp(sanitized);
  };

  const filterPageFields = (page) => {
    const oneFieldHasValue = (fields) =>
      fields.split(',').some((field) => replacements[field]);
    return {
      ...page,
      fields: page.fields
        .filter((field) =>
          field.onlyIf ? oneFieldHasValue(field.onlyIf) : true
        )
        .map(({ onlyIf: _, ...field }) => {
          if (field.type !== 'checkbox') return field;
          return {
            ...field,
            choices: field.choices.reduce((acc, ch) => {
              const { choice = ch, onlyIf } = ch;
              return onlyIf && oneFieldHasValue(onlyIf)
                ? [...acc]
                : [...acc, choice];
            }, []),
          };
        }),
    };
  };

  const renderLastPageContent = () => {
    if (mailchimp.status === 'subscribed') {
      return (
        <form className={mergeClassNames(styles.page)}>
          <h5>
            Bitte wenden Sie sich an uns, wenn Sie Ihre Daten ändern lassen
            wollen.
          </h5>
          <Button
            className="is-outlined"
            link
            inverted
            url={mailchimp.data?.msg.match(/.*href="(.*)".*/)[1]}
          >
            Zur Änderungsseite
          </Button>
        </form>
      );
    }

    if (mailchimp.status === 'success') {
      return (
        <form className={mergeClassNames(styles.page)}>
          <h5>Wir werden Sie in Kürze kontaktieren.</h5>
        </form>
      );
    }

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={mergeClassNames(styles.lastPage, styles.page)}
      >
        <CheckRadio
          type="checkbox"
          name="disclaimer"
          label="Zustimmung Datenverarbeitung"
          errors={errors}
          ref={register({ required: errorMessages.required })}
          onClick={toggleProcessingModal}
        />
        <Modal isVisible={processingModalVisible} hide={toggleProcessingModal}>
          <Translate resourceKey="disclaimer.processing-disclaimer" />
        </Modal>
        <h4 className="title">Wie können wir Sie erreichen?</h4>
        {renderFields({
          fields: [
            {
              type: 'checkbox',
              name: 'contmail',
              choices: ['E-Mail'],
            },
          ].concat(
            replacements.phone
              ? [
                {
                  type: 'checkbox',
                  name: 'contphone',
                  choices: ['Telefon'],
                },
              ]
              : []
          ),
          errors,
          register,
          getValues,
        })}
        <Button
          className="is-outlined"
          type="submit"
          inverted
          disabled={!isValid || mailchimp.status}
        >
          {translateToString(
            `signup.status-${mailchimp.status || 'subscribe'}`
          )}
        </Button>
      </form>
    );
  };

  const onArrowClick = (direction) => () => {
    if (direction === 'right') {
      onPageSet(pageIndex + 2);
    } else if (direction === 'left') {
      onPageSet(pageIndex);
    }
  };

  const onValid = useCallback(
    i => {
      return (isValid, values) => {
        setValidPages((state) => {
          state[i] = isValid;
          return [...state];
        });
        setValues((state) => ({ ...state, ...values }));
      }

    },
    [],
  )


  return (
    <section
      className={mergeClassNames(
        'section',
        styles.root,
        !content.hasBackgroundColor && styles.background
      )}
    >
      <div className="container content">
        {content && <RichText className={styles.content} content={content} />}
        <div
          className={mergeClassNames(
            styles.sliderWrapper,
            pageIndex >= 0 && styles.surveyStarted,
            pageIndex < json.pages.length && styles.rightVisible,
            pageIndex >= 1 && styles.leftVisible
          )}
        >
          <button className={styles.left} onClick={onArrowClick('left')}>
            <i className="fas fa-angle-left"></i>
          </button>
          <Slider {...mainProps} ref={cardWrapper}>
            <Card title={json.firstPage.title}>
              <Button
                inverted
                className={mergeClassNames('is-outlined', styles.startButton)}
                onClick={() => onPageSet(1)}
              >
                Beginnen
              </Button>
            </Card>
            {json.pages.map((page, i) => {
              const { fields, title, isGrid } = filterPageFields(page);
              return (
                <Card
                  key={`page_${i}`}
                  title={getTitle(title, replacements)}
                  pages={json.pages}
                  currentPageIndex={pageIndex}
                  setPage={onPageSet}
                  disabledUntil={getMaxPage()}
                  withNavigation
                >
                  <Page
                    isGrid={isGrid}
                    fields={fields}
                    onValid={onValid(i)}
                  />
                </Card>
              );
            })}
            <Card
              title={getTitle(
                json.lastPage[mailchimp.status || 'title'] ||
                json.lastPage.title,
                replacements
              )}
              pages={json.pages}
              currentPageIndex={pageIndex}
              setPage={onPageSet}
              disabledUntil={getMaxPage()}
              withNavigation
            >
              {renderLastPageContent()}
            </Card>
          </Slider>
          <button
            className={styles.right}
            disabled={getMaxPage() < pageIndex + 1}
            onClick={onArrowClick('right')}
          >
            <i className="fas fa-angle-right"></i>
          </button>
        </div>
      </div>
    </section>
  );
};

CustomerProfile.propTypes = {};
