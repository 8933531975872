import React from 'react';
import { mergeClassNames } from '../utils';

import RichText from './RichText';
import styles from './StandardContentBlock.module.css';

const StandardContentBlock = props => {
  const { className, content, children, centered } = props;
  return (
    <RichText
      {...props}
      className={mergeClassNames(
        className,
        centered && styles.centered,
        styles.standardContentBlock
      )}
      content={content}
    >
      {children}
    </RichText>
  );
};

export default StandardContentBlock;

export const CenteredStandardContentBlock = props => (
  <StandardContentBlock {...props} centered>
    {props.children}
  </StandardContentBlock>
);
