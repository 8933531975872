import React from 'react';
import { mergeClassNames } from '../utils';
import styles from './Banner.module.css';
import Translate from './Translate';

const Banner = ({ className, resourceKey, navCollapsed }) => {
  return (
    <div
      className={mergeClassNames(
        className,
        styles.root,
        styles.visible,
        navCollapsed && styles.hidden
      )}
    >
      <Translate resourceKey={resourceKey} />
    </div>
  );
};

Banner.propTypes = {};

export default Banner;
