import React, { Fragment, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import fallbackLogo from '../assets/logo.png';
import logo from '../assets/logo.svg';
import { mergeClassNames, navigateTo } from '../utils';
import Banner from './Banner';
import { InfoNotification } from './InfoNotification';
import CustomLink from './Link';
import styles from './Navbar.module.css';
import Translate from './Translate';



const Navbar = ({ location: { state }, pages }) => {
  const [mobileVisible, setMobileVisible] = useState(false);
  const [navCollapsed, setNavCollapsed] = useState(false);
  const onBurgerClick = () => setMobileVisible(!mobileVisible);

  const scrollListener = () => {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    const threshold = 100;
    setNavCollapsed(scrollTop >= threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const renderRoutes = (pages) => {
    if (!pages) return null;
    return pages.reduce((red, { path, title, childPages }) => {
      if (childPages && childPages.length) {
        return [
          ...red,
          <div key={path} className="navbar-item has-dropdown is-hoverable">
            <CustomLink partiallyActive className="navbar-item" to={path}>
              {title}
            </CustomLink>
            <div className="navbar-dropdown">{renderRoutes(childPages)}</div>
          </div>,
        ];
      }
      return [
        ...red,
        <CustomLink className="navbar-item" key={path} to={path}>
          {title}
        </CustomLink>,
      ];
    }, []);
  };

  const isInternalRedirect = state && state.internal;

  const navWrapperClass = mergeClassNames(
    styles.root,
    isInternalRedirect && styles.isInternalRedirect
  );

  const navClass = mergeClassNames(
    'navbar',
    styles.nav,
    mobileVisible && styles.mobileVisible,
    navCollapsed && styles.navCollapsed
  );

  const navMenuClass = mergeClassNames(
    'navbar-menu',
    mobileVisible && styles.mobileMenu,
    mobileVisible && 'is-active'
  );

  return (
    <Fragment>
      <div className={styles.pusher}></div>
      <div
        className={navWrapperClass}
        role="navigation"
        aria-label="main navigation"
      >
        <Banner
          resourceKey="banner"
          navCollapsed={navCollapsed}
          className={styles.banner}
        />
        {!isInternalRedirect && <InfoNotification resourceKey="notification" />}
        <nav
          className={navClass}
          role="navigation"
          aria-label="main navigation"
        >
          <div className={styles.backdrop}>
            <SVG className={styles.loaderSvg} src={logo} uniquifyIDs={false}>
              <img src={fallbackLogo} alt="VH-Gruppe" />
            </SVG>
          </div>
          <div className={`navbar-brand ${styles.brand}`}>
            <SVG
              className={styles.svg}
              src={logo}
              uniquifyIDs={false}
              onClick={() => navigateTo('/')}
            >
              <img src={fallbackLogo} alt="VH-Gruppe" />
            </SVG>
            <div
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded={mobileVisible}
              onClick={onBurgerClick}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>
          <div className={navMenuClass}>
            <div className={`navbar-start ${styles.routes}`}>
              {renderRoutes(pages)}
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className={`buttons ${styles.button}`}>
                  <Translate root resourceKey="navigation.contact" />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
