import React from 'react';
import { Link } from 'gatsby';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import { isInternalURL } from '../utils';

const renderDescription = description => {
  if (!description) return null;

  const [uri] = description.match(/(https:\/\/\S*)/);
  if (uri) {
    const children = description.replace(uri, '');
    if (isInternalURL(uri))
      return (
        <Link to={uri} state={{ internal: true }}>
          {children}
        </Link>
      );
    return (
      <a target="_blank" rel="noopener noreferrer" href={uri}>
        {children}
      </a>
    );
  }
  return <p>{description}</p>;
};

const ImageWithDescription = ({ className, image, ...props }) => {
  return (
    image && (
      <div className={className}>
        <PreviewCompatibleImage
          className="image card"
          image={image}
          {...props}
        ></PreviewCompatibleImage>
        {renderDescription(image.description)}
      </div>
    )
  );
};

export default ImageWithDescription;
