import React from 'react';
import { isRental, mergeClassNames, navigateTo } from '../utils';
import styles from './EstatePreview.module.css';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import RichText from './RichText';
import Translate from './Translate';

const EstatePreview = (props) => {
  const {
    title,
    marketingType,
    className,
    url,
    slug,
    freeFormTexts,
    attachments,
    withFlag,
    resources,
  } = props;

  const classNames = mergeClassNames(
    className,
    styles.root,
    url && styles.link
  );

  return (
    <div className={classNames}>
      <div
        className={styles.imageWrapper}
        onClick={() => navigateTo(url || `/angebote/${slug}/`)}
      >
        {withFlag && (
          <div className={styles.flag}>
            <p>{isRental(marketingType) ? 'Miete' : 'Kauf'}</p>
          </div>
        )}
        {attachments?.[0] && (
          <PreviewCompatibleImage
            useSquared
            className={`image ${styles.image}`}
            image={attachments[0]}
          />
        )}
        <div className={styles.overlay}>
          <h3>{title}</h3>
        </div>
      </div>
      <div className={styles.description}>
        <RichText content={freeFormTexts?.[0]?.value} />
        <Translate
          className={styles.cta}
          resources={resources}
          resourceKey="cta."
        />
      </div>
    </div>
  );
};

EstatePreview.propTypes = {};

export default EstatePreview;
