import { BLOCKS } from '@contentful/rich-text-types';
import React, { useEffect } from 'react';
import { mergeClassNames, translate, useHidden } from '../utils';

import styles from './InfoNotification.module.css';
import { useMeta } from './MetaProvider';
import RichText from './RichText';

export const InfoNotification = ({ resourceKey }) => {
  const [isHidden, setIsHidden] = useHidden({ resourceKey, timeout: 3000 });
  const { config, resources } = useMeta();

  const result = translate(resources, resourceKey);
  const noResult = !result?.json;

  useEffect(() => {
    if (!noResult) {
      new FontFace(
        'Caveat',
        'url(https://fonts.googleapis.com/css?family=Caveat&display=swap)'
      ).load();
    }
  }, [noResult]);

  if (noResult) return null;

  const content = {
    json: {
      ...result.json,
      content: [
        result.json.content.find(
          ({ nodeType }) => nodeType === BLOCKS.EMBEDDED_ASSET
        ),
        {
          content: result.json.content.filter(
            ({ nodeType }) => nodeType !== BLOCKS.EMBEDDED_ASSET
          ),
          data: {},
          nodeType: 'wrapper',
        },
      ],
    },
  };

  const onHide = () => setIsHidden(true);

  return (
    <div
      className={mergeClassNames(
        styles.root,
        isHidden ? styles.hidden : styles.visible
      )}
      onClick={onHide}
    >
      <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
        <button className="delete" onClick={onHide} />
        <RichText config={config} content={content} />
      </div>
    </div>
  );
};
