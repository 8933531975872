import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css';

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible(!isVisible);
  return [isVisible, toggle];
};

const Modal = ({ isVisible, hide, children }) => {
  if (!isVisible) return null;
  return ReactDOM.createPortal(
    <Fragment>
      <div className={styles.overlay} />
      <div
        className={styles.wrapper}
        onClick={hide}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className={styles.modal}>
          <button
            type="button"
            className={styles.closeButton}
            data-dismiss="modal"
            aria-label="Close"
            onClick={hide}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div>{children}</div>
        </div>
      </div>
    </Fragment>,
    document.body
  );
};

export default Modal;
