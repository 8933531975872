import React, { Component } from 'react';
import Slider from 'react-slick';
import { mergeClassNames } from '../utils';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import styles from './CustomSlider.module.css';

const Arrow = props => {
  const { className, style, onClick, direction } = props;
  return (
    <span
      style={{ ...style, [direction]: 0 }}
      className={`${className} ${styles.arrow}`}
      onClick={onClick}
    >
      <i className={`far fa-arrow-alt-circle-${direction}`}></i>
    </span>
  );
};

const mainProps = {
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  arrows: false,
};

const previewProps = {
  ...mainProps,
  fade: false,
  autoplay: true,
  centerMode: true,
  slidesToShow: 3,
  arrows: true,
  // variableWidth: true,
  nextArrow: <Arrow direction="right"></Arrow>,
  prevArrow: <Arrow direction="left"></Arrow>,
};

const singleImage = {
  infinite: false,
  autoplay: false,
};

class CustomSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main: null,
      preview: null,
    };
  }

  componentDidMount() {
    this.setState({
      main: this.main,
      preview: this.preview,
    });
  }

  goToSlide(i) {
    // this.main.slickGoTo(i);
    this.preview.slickGoTo(i);
  }

  mapItems = (attachments, isMain) => {
    return attachments.map((attachment, i) => {
      const {
        file: { contentType, url },
        title,
      } = attachment;
      const key = `${title}_${isMain ? 'main' : 'preview'}_${i}`;
      const goTo = () => !isMain && this.goToSlide(i);
      if (contentType && contentType.includes('pdf')) {
        return (
          <a
            key={key}
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            onClick={goTo}
          >
            <div className={styles.slide}>
              <object data={url} type={contentType}>
                <embed src={url} type={contentType}></embed>
              </object>
              <p>{title}</p>
            </div>
          </a>
        );
      }
      return (
        <div key={key} className={styles.slide} onClick={goTo}>
          <PreviewCompatibleImage
            contain
            backgroundFill={isMain}
            className={styles.image}
            image={attachment}
          />
          <p className={styles.imageTitle}>{title}</p>
        </div>
      );
    });
  };
  render() {
    const { attachments, className } = this.props;
    if (!attachments) return null;

    const hasOneImage = attachments.length <= 1;
    let dynamicProps = {};
    if (hasOneImage) {
      dynamicProps = singleImage;
    }

    return (
      <div className={mergeClassNames(className, styles.root)}>
        <Slider
          {...mainProps}
          {...dynamicProps}
          className={styles.main}
          asNavFor={this.state.preview}
          ref={slider => (this.main = slider)}
        >
          {this.mapItems(attachments, true)}
        </Slider>
        {!hasOneImage && (
          <Slider
            {...previewProps}
            {...dynamicProps}
            className={styles.preview}
            asNavFor={this.state.main}
            ref={slider => (this.preview = slider)}
          >
            {this.mapItems(attachments)}
          </Slider>
        )}
      </div>
    );
  }
}

export default CustomSlider;
