import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useJsApiLoader, GoogleMap, Circle } from '@react-google-maps/api';

export const googleMapsApiKey = 'AIzaSyDHCg98fMVaORzRKb9582Zx7ofL4r66C98';
export const googleMapsId = 'google-map-script';

const libraries = ['places'];
export const useAutoComplete = (ref) => {
  const { isLoaded } = useJsApiLoader({
    id: googleMapsId,
    googleMapsApiKey,
    libraries,
  });

  useMemo(() => {
    if (!isLoaded || !ref.current) return;
    const autoComplete = new window.google.maps.places.Autocomplete(
      ref.current,
      {
        types: ['address'],
        componentRestrictions: { country: 'de' },
      }
    );
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.addListener('place_changed', () => {});
    return autoComplete;
  }, [isLoaded, ref]);
};

export const Map = ({ address, showCircle, coords = null }) => {
  const { isLoaded } = useJsApiLoader({
    id: googleMapsId,
    googleMapsApiKey,
    libraries,
  });

  const [position, setPosition] = useState(coords);

  useEffect(() => {
    if (isLoaded && !position) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const [
            {
              geometry: { location },
            },
          ] = results;
          setPosition({
            lat: location.lat(),
            lng: location.lng(),
          });
        } else {
          console.error({ results, status });
        }
      });
    }
  }, [isLoaded]);

  const onClick = useCallback(() => {
    if (!position) return;
    const win = window.open(
      `https://maps.google.com/?q=${position.lat},${position.lng}`,
      '_blank'
    );
    win.focus();
  }, [position]);

  if (!isLoaded || !position) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%', marginBotton: 20 }}
      center={position}
      onClick={onClick}
      options={{
        scrollwheel: false,
        streetViewControl: false,
        mapTypeControl: false,
      }}
      zoom={15}
    >
      {showCircle && (
        <Circle
          center={position}
          radius={150}
          options={{
            strokeWeight: 1.0,
            strokeColor: '#4d64ac',
            fillColor: 'rgba(77,100,172,0.5)',
          }}
        />
      )}
      <></>
    </GoogleMap>
  );
};
