import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useMeta } from './components/MetaProvider';

export const mergeClassNames = (...classNames) =>
  classNames.filter(Boolean).join(' ');

export const renderRoutesRecursively = (pages) => {
  if (!pages) return null;
  return pages.map(({ path, title, childPages }) => (
    <ul key={path}>
      <li>
        <Link to={path}>{title}</Link>
      </li>
      {renderRoutesRecursively(childPages)}
    </ul>
  ));
};

export const isInternalURL = (url) =>
  !url.match(/^(ftp:\/\/|http:\/\/|https:\/\/|mailto:|tel:|www\.).*/);

export const navigateTo = (url) => {
  if (url) {
    if (isInternalURL(url)) {
      navigate(url, { state: { internal: true } });
    } else {
      const win = window.open(url, '_blank');
      if (win) win.focus();
    }
  }
};

export const translate = (resources, key) => {
  const result = (resources || []).find((resource) =>
    resource.key.startsWith(key)
  );
  if (result) return result.value;
  console.warn(`No translation found for ${key}`);
  return;
};

export const translateToString = (resources, key) => {
  const result = translate(resources, key);
  return documentToPlainTextString(result?.json);
};

export const useTranslate = (additionalResources = [], withMeta = true) => {
  const { resources } = useMeta();
  const resrc = withMeta
    ? [...additionalResources, ...resources]
    : additionalResources;
  return {
    translateToString: (key) => translateToString(resrc, key),
    translate: (key) => translate(resrc, key),
  };
};

export const initLocalized = ({ code, fallbackCode } = {}) => (property) =>
  property?.[code] || property?.[fallbackCode];

export const useHidden = ({ resourceKey, timeout = 2500 }) => {
  const { translate } = useTranslate();

  const valueExists = resourceKey ? !!translate(resourceKey)?.json : false;
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (!resourceKey || valueExists) {
      setTimeout(() => setIsHidden(false), timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isHidden, setIsHidden];
};

export const useFetch = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (url, options) => {
    setIsLoading(true);
    try {
      const res = await fetch(url, options);
      try {
        setResponse(await res.json());
      } catch (error) {
        setResponse(await res.text());
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ response, error, isLoading }, fetchData];
};

export const useResizeListener = (callback, withInitial = false) => {
  useEffect(() => {
    window.addEventListener('resize', callback);
    withInitial && window.dispatchEvent(new Event('resize'));
    return () => {
      window.removeEventListener('resize', callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const getMediaQuery = (width) => {
  if (width >= 1408) return 'fullhd';
  if (width >= 1216) return 'widescreen';
  if (width >= 1024) return 'desktop';
  if (width >= 769) return 'tablet';
  return 'mobile';
};

export const isRental = (marketingType) => !!marketingType.match(/rent/i);
