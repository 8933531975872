import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

const visibleOnce = (Component, visibilitySensorProps = {}) => (props) => {
  const [isVisible, setVisibility] = useState(false);
  const updateVisibility = (becameVisible) => {
    if (!isVisible && becameVisible) setVisibility(true);
  };

  return (
    <VisibilitySensor
      partialVisibility
      onChange={updateVisibility}
      {...visibilitySensorProps}
    >
      <Component isVisible={isVisible} {...props} />
    </VisibilitySensor>
  );
};

export default visibleOnce;
