import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import config from '../config';
import { mergeClassNames } from '../utils';

import styles from './PreviewCompatibleImage.module.css';

const PreviewCompatibleImage = props => {
  const {
    className,
    style,
    image,
    minHeight,
    contain,
    backgroundFill,
    isIE,
    useSquared,
    ...imageProps
  } = props;
  if (!image) return null;

  if (typeof image === 'string') {
    return (
      <img style={style} className={className} src={image} {...imageProps} />
    );
  }

  const { title: alt, src } = image;

  if (isIE) {
    return (
      <div
        style={{ ...style, backgroundImage: `url(${src})` }}
        className={mergeClassNames(
          className,
          styles.img,
          minHeight && styles.minHeight,
          contain && styles.contain,
          imageProps.onClick && styles.pointer
        )}
        alt={alt}
        {...imageProps}
      />
    );
  }

  const fluid = (useSquared ? image.squared : image.fluid) || image;

  return (
    <span onClick={imageProps.onClick}>
      {backgroundFill && (
        <Img
          className={styles.backgroundFill}
          fluid={fluid}
          alt={alt}
          {...imageProps}
        />
      )}
      <Img
        style={style}
        className={mergeClassNames(
          className,
          imageProps.onClick && styles.pointer
        )}
        fluid={fluid}
        alt={alt}
        {...imageProps}
      />
    </span>
  );
};

PreviewCompatibleImage.propTypes = {
  className: PropTypes.string,
  isIE: PropTypes.bool,
  style: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

PreviewCompatibleImage.defaultProps = {
  className: '',
  isIE: config.isIE,
};

export default PreviewCompatibleImage;
