import React, { useContext } from 'react';

const Context = React.createContext({});

export const MetaProvider = ({ children, ...state }) => {
  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const MetaConsumer = Context.Consumer;

export const useMeta = () => {
  const meta = useContext(Context);
  return {
    ...meta,
    resources: meta.resources || [],
  };
};
