import PropTypes from 'prop-types';
import React from 'react';
import { Parallax } from 'react-parallax';

import styles from './Parallax.module.css';

const ParallaxWrapper = ({
  image,
  childClassName,
  bgClassName,
  parallaxProps,
}) => {
  const { fluid, title } = image;

  return (
    <Parallax
      bgClassName={`${bgClassName} ${styles.root}`}
      bgImage={fluid.src}
      bgImageSizes={fluid.sizes}
      bgImageSrcSet={fluid.srcSet}
      bgImageAlt={title}
      strength={100}
      {...parallaxProps}
    >
      <div className={`${styles.child} ${childClassName}`} />
    </Parallax>
  );
};

ParallaxWrapper.propTypes = {
  image: PropTypes.object.isRequired,
  parallaxProps: PropTypes.object,
  childClassName: PropTypes.string,
  bgClassName: PropTypes.string,
};

ParallaxWrapper.defaultProps = {
  childClassName: '',
  parallaxProps: {},
  bgClassName: '',
};

export default ParallaxWrapper;
