import cloneDeep from 'lodash.clonedeep';
import React, { useState } from 'react';
import {
    getMediaQuery,
    mergeClassNames,
    navigateTo,
    useResizeListener
} from '../utils';
import EstatePreview from './EstatePreview';
import styles from './EstatesView.module.css';
import OverlappingTileBlock from './OverlappingTileBlock';
import { ErrorComponent } from './PageContentRouter';
import richTextStyles from './RichText.module.css';
import standardStyles from './StandardContentBlock.module.css';
import Translate from './Translate';

const addHeading = (content, heading) => {
  const clone = cloneDeep(content?.value);
  if (!clone) return;
  clone.json.content.unshift({
    data: {},
    content: [
      {
        data: {},
        marks: [],
        value: heading,
        nodeType: 'text',
      },
    ],
    nodeType: 'heading-3',
  });
  return clone;
};

const chunkify = (a, size) => {
  if (!size) return [a];
  return Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
    a.slice(i * size, i * size + size)
  );
};

const Tiles = ({ estates, notCentered, resources }) => {
  const [split, setSplit] = useState(0);

  useResizeListener(({ target }) => {
    const mq = getMediaQuery(target.innerWidth);
    const sizes = {
      mobile: 1,
      tablet: 2,
    };
    setSplit(sizes[mq] ?? 3);
  }, true);

  return chunkify(estates, split).map((estates, i) => {
    return (
      <div
        key={`chunk-${i}`}
        className={mergeClassNames(
          richTextStyles.inlineEmbedded,
          notCentered && richTextStyles.isEstateBlock,
          styles.tiles
        )}
      >
        {estates?.map((estate, y) => {
          return (
            <EstatePreview
              key={`${estate.title} ${i}:${y}`}
              withFlag
              resources={resources}
              {...estate}
            />
          );
        })}
      </div>
    );
  });
};

const Banner = ({ estates }) => {
  return (
    <div>
      {estates?.map(({ freeFormTexts, title, url, ...estate }, i) => {
        return (
          <OverlappingTileBlock
            {...estate}
            className={mergeClassNames(url && standardStyles.clickable)}
            key={title}
            onClick={() => navigateTo(url)}
            isOdd={!(i % 2)}
            content={
              freeFormTexts ? addHeading(freeFormTexts[0], title) : undefined
            }
          />
        );
      })}
    </div>
  );
};

const renderPageEstates = (props) => {
  const { typename, type } = props;
  switch (type) {
    case 'Tiles - centered':
      return <Tiles {...props} />;
    case 'Tiles':
      return <Tiles notCentered {...props} />;
    case 'Banner':
      return <Banner {...props} />;
    default:
      return <ErrorComponent name={`'${typename} > ${type}'`} />;
  }
};

const EstatesView = (props) => {
  const { estates, className, resources } = props;
  return (
    <div className={className}>
      {resources && (
        <Translate
          withMeta={false}
          className={mergeClassNames(
            standardStyles.standardContentBlock,
            standardStyles.noPaddingBottom
          )}
          preferredResources={resources}
          resourceKey="text."
        />
      )}
      {!estates?.length ? (
        <Translate
          className={mergeClassNames(
            standardStyles.centered,
            standardStyles.standardContentBlock,
            standardStyles.noPaddingBottom
          )}
          preferredResources={resources}
          resourceKey="emptytext."
        />
      ) : (
        renderPageEstates(props)
      )}
      {resources && (
        <Translate
          withMeta={false}
          className={mergeClassNames(
            standardStyles.centered,
            standardStyles.standardContentBlock
          )}
          preferredResources={resources}
          resourceKey="button."
        />
      )}
    </div>
  );
};

export default EstatesView;
