import React from 'react';

import RichText from './RichText';

import { mergeClassNames } from '../utils';
import ImageWithDescription from './ImageWithDescription';
import styles from './InlinedImageBlock.module.css';

const InlinedImageBlock = ({ attachments, className, content }) => {
  const classNames = mergeClassNames(className, styles.root);

  return (
    <section className={classNames}>
      {attachments && (
        <ImageWithDescription
          className={styles.image}
          image={attachments[0]}
        ></ImageWithDescription>
      )}
      <RichText className={styles.text} content={content}></RichText>
    </section>
  );
};

export default InlinedImageBlock;
