import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Link.module.css';

const CustomLink = ({ className, to, children, partiallyActive }) => {
  return (
    <Link
      className={`${styles.root} ${className}`}
      to={to}
      partiallyActive={partiallyActive}
      activeClassName={styles.active}
      state={{ internal: true }}
    >
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

CustomLink.defaultProps = {
  className: '',
};

export default CustomLink;
