import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { initLocalized, isInternalURL, mergeClassNames } from '../utils';
import Button from './Button';
import { useMeta } from './MetaProvider';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import styles from './RichText.module.css';

const initOptions = ({ localization: { locale } }, renderer = {}) => {
  const resolve = initLocalized(locale);

  const renderEntryHyperlink = (node, children) => {
    const { url, label } = node.data.target.fields || {};
    const lbl = resolve(label);
    const path = resolve(url);
    if (!path) return;
    return (
      <Button
        className={mergeClassNames('is-outlined', styles.standardButton)}
        internalLink={!path.match(/^(ftp|http|https):\/\/.*/)}
        url={path}
        link
      >
        {lbl}
      </Button>
    );
  };

  const renderAsset = (node, children) => {
    const asset = resolve(node.data.target.fields?.file);
    if (!asset) return;
    return <PreviewCompatibleImage className="image" image={asset.url} />;
  };

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: renderEntryHyperlink,
      [BLOCKS.EMBEDDED_ASSET]: renderAsset,
      [BLOCKS.PARAGRAPH]: renderParagraph,
      [BLOCKS.HEADING_1]: renderHeading('h1'),
      [BLOCKS.HEADING_2]: renderHeading('h2'),
      [BLOCKS.HEADING_3]: renderHeading('h3'),
      [BLOCKS.HEADING_4]: renderHeading('h4'),
      [BLOCKS.HEADING_5]: renderHeading('h5'),
      [BLOCKS.HEADING_6]: renderHeading('h6'),
      [INLINES.EMBEDDED_ENTRY]: renderEntryHyperlink,
      [INLINES.ENTRY_HYPERLINK]: renderEntryHyperlink,
      [INLINES.HYPERLINK]: renderHyperLink,
      wrapper: (node, children) => {
        return <div>{children}</div>;
      },
      ...(renderer.renderNode || {}),
    },
    renderMark: {
      // [MARKS.BOLD]: text => <strong>{text}</strong>,
      ...(renderer.renderMark || {}),
    },
  };
};

const renderParagraph = (node, children) => {
  const isEmpty = !children.filter((item) => item).length;
  if (isEmpty) return null;

  const isParagraph = children.every((item) => typeof item === 'string');
  if (isParagraph) return <p>{children}</p>;

  const isInline = children.some(
    (item) => item && item.props && item.props.isInline
  );

  if (isInline) {
    const isEstateBlock = children.some((item) =>
      item?.props?.className?.includes('content-type-estate')
    );

    return (
      <div
        className={mergeClassNames(
          styles.inlineEmbedded,
          isEstateBlock && styles.isEstateBlock
        )}
      >
        {children}
      </div>
    );
  }

  return <p>{children}</p>;
};

const renderHeading = (Tag = 'h1') => (node, __html) => {
  return <Tag dangerouslySetInnerHTML={{ __html }}></Tag>;
};

const renderHyperLink = (node, children) => {
  const { uri } = node.data;
  if (isInternalURL(uri))
    return (
      <Link to={uri} state={{ internal: true }}>
        {children}
      </Link>
    );

  return (
    <a target="_blank" rel="noopener noreferrer" href={uri}>
      {children}
    </a>
  );
};

const RichText = ({ root, className, content, renderer }) => {
  const { config } = useMeta();
  if (!content || !content.json) return null;

  const options = initOptions(config, renderer);
  const classNames = mergeClassNames(className, 'content');

  if (root) {
    return documentToReactComponents(content.json, options);
  }

  return (
    <article className={classNames}>
      {documentToReactComponents(content.json, options)}
    </article>
  );
};

const localeShape = PropTypes.shape({
  code: PropTypes.string.isRequired,
  localizedPaths: PropTypes.object.isRequired,
});

RichText.propTypes = {
  className: PropTypes.string,
  json: PropTypes.object,
  config: PropTypes.shape({
    localization: PropTypes.shape({
      locale: localeShape,
      locales: PropTypes.arrayOf(localeShape),
    }).isRequired,
  }).isRequired,
  renderer: PropTypes.shape({
    renderNode: PropTypes.object,
    renderMark: PropTypes.object,
  }),
};

RichText.defaultProps = {
  config: { localization: {} },
};

export default RichText;
