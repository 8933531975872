import React from 'react';

import { mergeClassNames } from '../utils';
import styles from './HeroImageBlock.module.css';
import Parallax from './Parallax';
import RichText from './RichText';

function HeroImageBlock({ attachments, content, className }) {
  const classNames = mergeClassNames(className, styles.root, styles.fullScreen);

  return (
    <section className={classNames}>
      <Parallax
        image={attachments[0]}
        childClassName={styles.image}
        parallaxProps={{ strength: 200 }}
      ></Parallax>
      <div className={styles.overlay}>
        <RichText content={content}></RichText>
      </div>
    </section>
  );
}

export default HeroImageBlock;
