import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import React, { Fragment } from 'react';
import SmoothScroll from 'smooth-scroll';
import { isRental, mergeClassNames } from '../utils';
import Button from './Button';
import CustomSlider from './CustomSlider';
import styles from './EstateDetail.module.css';
import EstatePrint from './EstatePrint';
import { Map } from './Google';
import RichText from './RichText';

const split = (array) => {
  if (!array.length) return [];
  const half = Math.floor(array.length / 2);
  return [array.slice(0, half), array.slice(half, array.length)];
};

const EstateDetail = ({ className, estate, resources }) => {
  if (!estate) return null;

  const {
    title,
    address,
    attachments,
    slug,
    freeFormTexts,
    specifications,
    estateType,
    marketingType,
    price,
    space,
    rooms,
    isReference,
  } = estate;

  const isSold = isReference;
  const soldText = `Bereits ${
    isRental(marketingType) ? 'vermietet' : 'verkauft'
  }`;

  const specificationSets = split(
    [
      {
        key: 'Adresse',
        value: address,
      },
      {
        key: 'Immobilientyp',
        value: estateType,
      },
      {
        key: 'Vermarktungsart',
        value: isRental(marketingType) ? 'Miete' : 'Kauf',
      },
      {
        key: isRental(marketingType) ? 'Miete' : 'Preis',
        value: price,
      },
      {
        key: 'Wohnfläche',
        value: space,
      },
      {
        key: 'Anzahl Räume',
        value: rooms,
      },
      ...(specifications || []),
    ].filter(({ value }) => !!value)
  );

  const printData = {
    title,
    specifications: {
      header: 'Objektdaten',
      sets: specificationSets,
    },
    isSold: isSold && soldText,
    texts: freeFormTexts,
    attachments,
    resources,
  };

  const specs = specificationSets.map((set, i) => (
    <div key={`table-${i}`}>
      <table className="table is-fullwidth">
        <tbody>
          {set.map(({ key, value }) => {
            return (
              <tr key={key}>
                <td title={key}>{key}</td>
                <td className={mergeClassNames('content')} title={value}>
                  {value.json ? (
                    <RichText content={value} />
                  ) : (
                    <article>
                      <p>{value}</p>
                    </article>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ));

  return (
    <div className={mergeClassNames(className, styles.root)}>
      <h1>
        {title}
        {isSold && <span className="tag is-danger is-rounded">{soldText}</span>}
      </h1>
      <section className={styles.block}>
        <CustomSlider className={styles.slider} attachments={attachments} />
        <div className={styles.details}>
          {Boolean(specs && specs.length) && (
            <Fragment>
              <h3>Objektdaten</h3>
              <div className={`content ${styles.tableWrapper}`}>{specs}</div>
            </Fragment>
          )}
          {freeFormTexts?.map(({ key, value }) => {
            if (!value) return null;
            return (
              <div key={key}>
                <h3>{key}</h3>
                <div className={styles.text}>
                  {<RichText content={value} />}
                </div>
              </div>
            );
          })}
          <Button
            className={styles.button}
            url={'#contactform'}
            onClick={() =>
              typeof window !== 'undefined' &&
              new SmoothScroll().animateScroll(
                document.querySelector('#contactform'),
                undefined,
                { offset: 100 }
              )
            }
          >
            Anfrage senden
          </Button>
        </div>
      </section>
      {address && (
        <div className={styles.map}>
          <Map address={documentToPlainTextString(address.json)} showCircle />
        </div>
      )}
      <EstatePrint fab data={printData} id={slug} />
    </div>
  );
};

EstateDetail.propTypes = {};

export default EstateDetail;
