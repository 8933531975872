import PropTypes from 'prop-types';
import React from 'react';
import { CustomerProfile } from './CustomerProfile';
import EstateDetail from './EstateDetail';
import EstatesView from './EstatesView';
import Footer from './Footer';
import { ContactForm } from './Form';
import HeroImage from './HeroImageBlock';
import InlinedImageBlock from './InlinedImageBlock';
import Navbar from './Navbar';
import { NewsletterSignup } from './NewsletterSignup';
import OverlappingTileBlock from './OverlappingTileBlock';
import StandardContentBlock, {
  CenteredStandardContentBlock
} from './StandardContentBlock';
import TextAndLinks from './TextAndLinks';

export const ErrorComponent = ({ name }) => {
  return (
    <p style={{ color: '#FF0000' }}>
      <code>"{name}" component missing</code>
    </p>
  );
};

const PageContentTypeRouter = (props) => {
  const { typename, type } = props;
  switch (type) {
    case 'Hero image':
      return <HeroImage {...props} />;
    case 'Centered text':
      return <CenteredStandardContentBlock {...props} />;
    case 'Left aligned text':
      return <StandardContentBlock {...props} />;
    case 'Text and links':
      return <TextAndLinks {...props} />;
    case 'Left aligned image':
      return <InlinedImageBlock {...props} />;
    case 'Overlapping image':
      return <OverlappingTileBlock {...props} />;
    case 'Contact form':
      return <ContactForm {...props} />;
    case 'Estate detail':
      return <EstateDetail {...props} />;
    case 'Newsletter signup':
      return <NewsletterSignup {...props} />;
    case 'Customer profile':
      return <CustomerProfile {...props} />;
    case 'Estates':
    default:
      return <ErrorComponent name={`'${typename} > ${type}'`} />;
  }
};

const PageContentRouter = (props) => {
  const { typename } = props;
  switch (typename) {
    case 'ContentfulPageNavigation':
      return <Navbar {...props} />;
    case 'ContentfulPageContent':
      return <PageContentTypeRouter {...props} />;
    case 'ContentfulPageFooter':
      return <Footer {...props} />;
    case 'ContentfulPageEstates':
      return <EstatesView {...props} />;
    default:
      return <ErrorComponent name={typename} />;
  }
};

PageContentRouter.propTypes = {
  typename: PropTypes.string.isRequired,
  type: PropTypes.string,
};

PageContentRouter.defaultProps = {
  typename: '<undefined>',
};

export default PageContentRouter;
