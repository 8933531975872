/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import React, { forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { mergeClassNames, useFetch } from '../utils';
import Modal, { useModal } from './Modal';

import Button from './Button';

import styles from './Form.module.css';
import { useAutoComplete } from './Google';
import { useMeta } from './MetaProvider';
import Translate from './Translate';

const ErrorMessage = ({ error }) => {
  return (
    <span
      className={mergeClassNames(
        styles.error,
        !!error?.message && styles.visible
      )}
    >
      {error?.message || ''}
    </span>
  );
};

export const CheckRadio = forwardRef(
  ({ className, type = 'checkbox', errors = {}, label, ...props }, ref) => {
    return (
      <label className={mergeClassNames(type, styles.checkbox, className)}>
        <input type={type} ref={ref} {...props} />
        {label}
        <ErrorMessage error={errors[props.name]} />
      </label>
    );
  }
);

export const Select = forwardRef(
  ({ className, errors = {}, options, ...props }, ref) => {
    return (
      <div
        className={mergeClassNames(
          'select',
          !!errors[props.name] && 'is-danger',
          styles.select,
          className
        )}
      >
        <select ref={ref} {...props}>
          {options.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </select>
        <ErrorMessage error={errors[props.name]} />
      </div>
    );
  }
);

export const Input = forwardRef(
  ({ className, Tag = 'input', errors = {}, suffix, ...props }, ref) => {
    return (
      <div
        className={mergeClassNames(styles.input, className)}
        style={{ '--suffix': suffix ? `'${suffix}'` : suffix }}
      >
        <Tag
          className={mergeClassNames(Tag, !!errors[props.name] && 'is-danger')}
          ref={ref}
          {...props}
        />
        <ErrorMessage error={errors[props.name]} />
      </div>
    );
  }
);

export const AddressInput = forwardRef(
  ({ className, errors = {}, suffix, ...props }, ref) => {
    const autoCompleteRef = useRef(null);
    useAutoComplete(autoCompleteRef);
    return (
      <div
        className={mergeClassNames(styles.input, className)}
        style={{ '--suffix': suffix ? `'${suffix}'` : suffix }}
      >
        <input
          className={mergeClassNames(
            'input',
            !!errors[props.name] && 'is-danger'
          )}
          ref={(e) => {
            ref(e);
            autoCompleteRef.current = e;
          }}
          {...props}
        />
        <ErrorMessage error={errors[props.name]} />
      </div>
    );
  }
);

export const joinCheckboxKeys = (keys, values) => {
  return keys.reduce((acc, checkboxKey) => {
    const joinedValue = Object.entries(values)
      .reduce((acc, [key, value]) => {
        if (key.split('_')[0] === checkboxKey && value) {
          return [...acc, value];
        }
        return acc;
      }, [])
      .join(', ');
    if (joinedValue) {
      return { ...acc, [checkboxKey]: joinedValue };
    }
    return acc;
  }, {});
};

export const errorMessages = {
  required: 'Eingabe wird benötigt',
  requiredOne: 'Mindestens eine Eingabe wird benötigt',
  minLength: 'Minimale Zeichenanzahl nicht erreicht',
  maxLength: 'Maximale Zeichenanzahl überschritten',
  email: 'Bitte eine gültige E-Mail Adresse verwenden',
};

const atLeastOneRequired = (keys, getValues) => {
  return {
    validate: {
      required: () => {
        const value = Object.values(joinCheckboxKeys(keys, getValues())).length;
        return !!value || errorMessages.requiredOne;
      },
    },
  };
};

export const registerOptions = {
  search: (_, getValues) => atLeastOneRequired(['search', 'offer'], getValues),
  offer: (_, getValues) => atLeastOneRequired(['search', 'offer'], getValues),
  contphone: (_, getValues) =>
    atLeastOneRequired(['contphone', 'contmail'], getValues),
  contmail: (_, getValues) =>
    atLeastOneRequired(['contphone', 'contmail'], getValues),
  number: {
    min: 0,
    valueAsNumber: true,
  },
  email: {
    required: errorMessages.required,
    pattern: {
      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
      message: errorMessages.email,
    },
    minLength: {
      value: 2,
      message: errorMessages.minLength,
    },
    maxLength: {
      value: 36,
      message: errorMessages.maxLength,
    },
  },
  name: {
    required: errorMessages.required,
    minLength: {
      value: 2,
      message: errorMessages.minLength,
    },
  },
  phone: {
    required: errorMessages.required,
    minLength: {
      value: 2,
      message: errorMessages.minLength,
    },
    maxLength: {
      value: 36,
      message: errorMessages.maxLength,
    },
  },
};

export const ContactForm = ({ config }) => {
  const { estateTitle } = useMeta();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      message:
        estateTitle &&
        `Bitte senden Sie mir das Exposé "${estateTitle.trim()}" zu.`,
    },
  });

  const [{ response, isLoading, error }, fetchData] = useFetch();
  const [privacyModalIsVisible, togglePrivacyModal] = useModal();
  const [processingModalVisible, toggleProcessingModal] = useModal();

  const onSubmit = (data) => {
    const path = config.env === 'production' ? 'sendmail' : 'dev-sendmail';
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...data, receiver: 'info@vh-gruppe.de' }),
    };
    fetchData(`/${path}`, options);
  };

  const getButtonText = () => {
    if (error) return 'Fehler - Bitte versuchen Sie es erneut';
    if (response) return 'Übermittelt';
    if (isLoading) return 'Wird abgeschickt';
    return 'Versenden';
  };

  return (
    <section id="contactform" className={`section ${styles.root}`}>
      <h2>Kontakt</h2>
      <div className="container content">
        <form
          className={styles.contactForm}
          onSubmit={handleSubmit(onSubmit)}
          id="contact-form"
        >
          <div>
            <Input
              name="name"
              errors={errors}
              placeholder="Name*"
              ref={register(registerOptions.name)}
            />
            <Input
              name="about"
              errors={errors}
              placeholder="Betreff"
              ref={register}
            />
            <Input
              name="mail"
              errors={errors}
              placeholder="E-Mail*"
              ref={register(registerOptions.email)}
            />
            <Input
              name="phone"
              errors={errors}
              placeholder="Telefon*"
              ref={register(registerOptions.phone)}
            />
            <Input
              Tag="textarea"
              name="message"
              rows={6}
              errors={errors}
              placeholder="Nachricht*"
              ref={register({
                required: errorMessages.required,
                minLength: {
                  value: 5,
                  message: errorMessages.minLength,
                },
                maxLength: {
                  value: 256,
                  message: errorMessages.maxLength,
                },
              })}
            />
            <CheckRadio
              type="checkbox"
              name="dataprivacy-disclaimer"
              label="Zustimmung Datenschutz"
              errors={errors}
              ref={register({ required: errorMessages.required })}
              onClick={togglePrivacyModal}
            />
            <Modal isVisible={privacyModalIsVisible} hide={togglePrivacyModal}>
              <Translate resourceKey="disclaimer.dataprivacy-disclaimer" />
            </Modal>
            <CheckRadio
              type="checkbox"
              name="processing-disclaimer"
              label="Zustimmung Datenverarbeitung"
              errors={errors}
              ref={register({ required: errorMessages.required })}
              onClick={toggleProcessingModal}
            />
            <Modal
              isVisible={processingModalVisible}
              hide={toggleProcessingModal}
            >
              <Translate resourceKey="disclaimer.processing-disclaimer" />
            </Modal>
          </div>
          <Button type="submit" disabled={!isValid}>
            {getButtonText()}
          </Button>
        </form>
      </div>
    </section>
  );
};

ContactForm.propTypes = {
  classes: PropTypes.shape({
    formWrapper: PropTypes.string,
    row: PropTypes.string,
    field: PropTypes.string,
  }).isRequired,
  contactformExplanation: PropTypes.string,
};

ContactForm.defaultProps = {
  classes: {},
};

export default ContactForm;
