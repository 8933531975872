import React from 'react';
import { useTranslate } from '../utils';
import { useMeta } from './MetaProvider';
import RichText from './RichText';

const Translate = ({ resourceKey, preferredResources, withMeta, ...props }) => {
  const { config } = useMeta();
  const { translate } = useTranslate(preferredResources, withMeta);
  return (
    <RichText {...props} config={config} content={translate(resourceKey)} />
  );
};

export default Translate;
