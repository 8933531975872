import { useState } from 'react';
import jsonp from 'jsonp';
import qs from 'qs';

const getAjaxUrl = (url) => url.replace('/post?', '/post-json?');

const baseUrl = 'https://vh-gruppe.us1.list-manage.com/subscribe/post';
export const newsletterAudienceUrl = `${baseUrl}?u=ed13b75df7f420f2f3bd80bed&id=1ffb1bc5e3`;
export const customerProfileAudienceUrl = `${baseUrl}?u=ed13b75df7f420f2f3bd80bed&amp;id=c69e03e449`;

const valueMap = {
  true: 'Ja',
  false: 'Nein',
};

export const mapData = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (Number.isNaN(value) || value === '') {
      return acc;
    }
    return {
      ...acc,
      [key.toUpperCase().substring(0, 10)]: valueMap[`${value}`] || value,
    };
  }, {});
};

const getStatus = ({ error, data } = {}) => {
  if (!error && !data) return 'sending';
  if (error) {
    console.error(error);
    return 'error';
  }
  if (data?.msg?.includes('already subscribed')) {
    return 'subscribed';
  }
  if (data?.msg?.includes('confirm')) {
    return 'confirm';
  }
  return data.result;
};

export const useMailchimp = (url) => {
  const [state, setState] = useState({
    status: null,
    error: null,
    data: null,
  });

  const setAssignState = (newState) =>
    setState((state) => ({ ...state, ...newState }));

  const subscribe = (data) => {
    const finalUrl = `${getAjaxUrl(url)}&${qs.stringify(data)}`;
    setAssignState({ status: getStatus() });

    jsonp(finalUrl, { param: 'c' }, (error, data) => {
      setAssignState({
        status: getStatus({ error, data }),
        error,
        data,
      });
    });

    // return new Promise((resolve, reject) => {
    //   jsonp(finalUrl, { param: 'c' }, (error, data) => {
    //     const result = {
    //       status: getStatus({ error, data }),
    //       error,
    //       data,
    //     };
    //     if (error) {
    //       reject(result);
    //     }
    //     resolve(result);
    //     setAssignState();
    //   });
  };

  return [state, subscribe];
};
