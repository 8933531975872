import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/Layout';
import PageContentRouter from '../components/PageContentRouter';
import styles from '../components/RichText.module.css';
import { mergeClassNames } from '../utils';

const Page = (props) => {
  const {
    data: { page, references, estate },
    pageContext: { config },
    location,
  } = props;

  const { pageContent, metadata, ...restData } = page;
  const referenceIds = references.estates?.map(({ id }) => id) ?? [];

  const enrichedEstate = estate
    ? { ...estate, isReference: referenceIds.includes(estate.id) }
    : undefined;

  const enrichedPageContent = pageContent.map((content) => {
    const { __typename, estates } = content;
    if (__typename !== 'ContentfulPageEstates' && !estates?.length)
      return content;
    return {
      ...content,
      estates:
        estates?.map((estate) => ({
          ...estate,
          isReference: referenceIds.includes(estate.id),
        })) ?? [],
    };
  });

  const resources = enrichedPageContent
    .reduce((red, { resources }) => [...red, ...(resources || [])], [])
    .filter(Boolean);

  return (
    <Layout
      config={config}
      metadata={metadata}
      resources={resources}
      estateTitle={enrichedEstate?.title}
    >
      {enrichedPageContent.map(({ __typename, ...content }, i) => {
        const isOdd = Boolean(i % 2);
        const classNames = mergeClassNames(
          `content-type-${__typename}`,
          content.hasBackgroundColor && styles.background
        );
        return (
          <PageContentRouter
            {...content}
            key={`${__typename}_${i}`}
            className={classNames}
            location={location}
            isOdd={isOdd}
            typename={__typename}
            estate={enrichedEstate}
            pageContext={restData}
            config={config}
          />
        );
      })}
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.shape({
    config: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    page: PropTypes.shape({
      pageContent: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }),
};

export default Page;

export const query = graphql`
  fragment attachment on ContentfulAsset {
    title
    description
    file {
      url
      contentType
    }
    fluid(maxWidth: 1280, quality: 80) {
      ...GatsbyContentfulFluid_noBase64
    }
    squared: fluid(maxWidth: 800, maxHeight: 800, quality: 80) {
      ...GatsbyContentfulFluid_noBase64
    }
  }

  fragment resource on ContentfulResource {
    key
    value {
      json
    }
  }

  fragment estateResource on ContentfulEstateResource {
    key
    value {
      json
    }
  }

  fragment paths on ContentfulPage {
    path
    title
    childPages {
      path
      title
    }
  }

  query(
    $contentful_id: String
    $node_locale: String
    $slug: String = "{{NOT_AVAILABLE}}"
  ) {
    references: contentfulPageEstates(
      contentful_id: { eq: "4KP8PXC24cL4uFCRN1rzAV" }
      node_locale: { eq: $node_locale }
    ) {
      estates {
        id
      }
    }
    page: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      path
      metadata {
        title
        description
      }
      pageContent {
        __typename
        ... on ContentfulPageNavigation {
          resources {
            ...resource
          }
          pages {
            ...paths
          }
        }
        ... on ContentfulPageEstates {
          type
          resources {
            ...resource
          }
          hasBackgroundColor
          estates {
            id
            title
            url
            slug
            marketingType
            freeFormTexts {
              ...estateResource
            }
            attachments {
              ...attachment
            }
          }
        }
        ... on ContentfulPageContent {
          type
          content {
            json
          }
          hasBackgroundColor
          resources {
            ...resource
          }
          attachments {
            ...attachment
          }
        }
        ... on ContentfulPageFooter {
          resources {
            ...resource
          }
          pages {
            ...paths
          }
        }
      }
    }
    estate: contentfulEstate(
      slug: { eq: $slug }
      node_locale: { eq: $node_locale }
    ) {
      id
      slug
      title
      marketingType
      estateType
      price
      space
      rooms
      url
      address {
        json
      }
      freeFormTexts {
        ...estateResource
      }
      specifications {
        key
        value
      }
      attachments {
        ...attachment
      }
    }
  }
`;
