import React, { Fragment } from 'react';
import styles from './Footer.module.css';
import CustomLink from './Link';
import Translate from './Translate';

const Footer = ({ pages }) => {
  const renderRoutes = (pages) => {
    return pages.reduce((red, { path, title, childPages }) => {
      if (childPages && childPages.length) {
        return [
          ...red,
          <Fragment key={path}>
            <CustomLink className={styles.link} to={path}>
              {title}
            </CustomLink>
            <div className={styles.indented}>{renderRoutes(childPages)}</div>
          </Fragment>,
        ];
      }
      return [
        ...red,
        <CustomLink key={path} className={styles.link} to={path}>
          {title}
        </CustomLink>,
      ];
    }, []);
  };

  const { left, right } = pages.reduce(
    (red, item) =>
      item.path.match(/impressum|kontakt|datenschutz/i)
        ? { ...red, right: [...red.right, item] }
        : { ...red, left: [...red.left, item] },
    { left: [], right: [] }
  );

  return (
    <footer className={`footer section ${styles.root}`}>
      <div className="container content">
        <div className={styles.header}>
          <h3>
            <Translate resourceKey="company.name" />
          </h3>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div>
              <h5 className={`subtitle ${styles.categoryHeader}`}>
                <span className="icon">
                  <i className="far fa-compass"></i>
                </span>
                Sitz
              </h5>
              <div>
                <a
                  href="https://goo.gl/maps/YWnyQ9dsw6kUdA9K6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Translate resourceKey="company.address" />
                </a>
              </div>
            </div>
            <div>
              <h5 className={`subtitle ${styles.categoryHeader}`}>
                <span className="icon">
                  <i className="far fa-address-book"></i>
                </span>
                Kontakt
              </h5>
              <table className="table is-narrow">
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td>
                      <Translate resourceKey="company.email" />
                    </td>
                  </tr>
                  <tr>
                    <td>Telefon</td>
                    <td>
                      <Translate resourceKey="company.phone" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.right}>
            <h5 className={`subtitle ${styles.categoryHeader}`}>
              <span className="icon">
                <i className="far fa-map"></i>
              </span>
              Links
            </h5>
            <div className={styles.routes}>
              <div>{renderRoutes(left)}</div>
              <div>
                {renderRoutes(right)}
                <div className={styles.links}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/vhgruppe/"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
