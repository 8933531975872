import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { mergeClassNames } from '../utils';
import styles from './Button.module.css';

const Button = ({
  internalLink,
  url,
  className,
  children,
  link,
  inverted,
  ...props
}) => {
  const classNames = mergeClassNames(
    className,
    styles.button,
    inverted && styles.inverted,
    'button is-small is-info'
  );
  if (internalLink) {
    return (
      <Link
        className={classNames}
        to={url}
        state={{ internal: internalLink, ...props.state }}
        {...props}
      >
        {children}
      </Link>
    );
  }
  const Tag = link ? 'a' : 'button';
  return (
    <Tag className={classNames} target="__blank" href={url} {...props}>
      {children}
    </Tag>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  internalLink: PropTypes.bool,
  url: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  className: '',
};

export default Button;
