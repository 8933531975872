import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';

import cfg from '../config';
import '../index.css';
import { translateToString } from '../utils';
import Button from './Button';
import styles from './Layout.module.css';
import { MetaProvider } from './MetaProvider';

const setupOptOut = (ga) => {
  // Disable tracking if the opt-out cookie exists.
  const disableStr = `ga-disable-${ga}`;
  if (document.cookie.includes(`${disableStr}=true`)) {
    window[disableStr] = true;
  }

  if (!window.gaOptout) {
    // Opt-out function
    window.gaOptout = () => {
      document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
      window[disableStr] = true;
    };
  }
};

const Layout = ({
  children,
  config,
  metadata: { title, description },
  location,
  estateTitle = '',
  pages,
  resources,
}) => {
  const {
    path,
    env,
    domain,
    localization: { locale },
  } = config;
  const isProduction = env === 'production';

  useEffect(() => {
    const { ga } = cfg;
    if (isProduction) {
      // disable console.log
      console.log = () => {};
      // disable console.info
      console.info = () => {};
      if (ga) {
        setupOptOut(ga);
        ReactGA.initialize(ga);
        ReactGA.set({ page: path, anonymizeIp: true });
        ReactGA.pageview(path);
      }
    }
  }, [isProduction, path]);

  const sanitizedTitle = title.replace('{{title}}', estateTitle);

  return (
    <MetaProvider
      config={config}
      location={location}
      pages={pages}
      resources={resources}
      estateTitle={estateTitle}
    >
      <Fragment>
        <Helmet>
          <html lang={locale.code} />
          <title>{sanitizedTitle}</title>
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/favicon-96x96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={sanitizedTitle} />
          <meta property="og:site_name" content={sanitizedTitle} />
          <meta property="og:url" content={path} />
          <meta property="og:image" content={`${domain}/vh-gruppe.png`} />
        </Helmet>
        <main>
          {children}
          <CookieConsent
            location="bottom"
            cookieName="accepted-cookies-vhgruppe"
            buttonText={translateToString(resources, 'cookienotice.button')}
            containerClasses={styles.cookieConsent}
            ButtonComponent={(args) => <Button {...args} link />}
          >
            <p>{translateToString(resources, 'cookienotice.message')}</p>
            <p>{translateToString(resources, 'cookienotice.link')}</p>
          </CookieConsent>
        </main>
      </Fragment>
    </MetaProvider>
  );
};

const localeShape = PropTypes.shape({
  code: PropTypes.string.isRequired,
  localizedPaths: PropTypes.object.isRequired,
});

Layout.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  config: PropTypes.shape({
    path: PropTypes.string.isRequired,
    localization: PropTypes.shape({
      locale: localeShape,
    }).isRequired,
    env: PropTypes.string.isRequired,
    domain: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
};

Layout.defaultProps = {};

export default Layout;
