import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import PDF from '../pdf/Document';
import styles from './EstatePrint.module.css';

const EstatePrint = ({ id, data, className = '' }) => {
  const name = `VHG-${id}-de.pdf`;
  const [loading, setLoading] = useState(false);

  const generatePdfDocument = (data, name) => {
    setLoading(true);
    pdf(<PDF data={data} />)
      .toBlob()
      .then(blob => saveAs(blob, name))
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  return (
    <button
      className={`${className} button is-medium is-rounded is-outlined ${styles.fab}`}
      title="Exposé erstellen"
      onClick={() => generatePdfDocument(data, name)}
    >
      <span className="icon">
        {loading ? (
          <i className="fas fa-circle-notch fa-spin"></i>
        ) : (
          <i className="fas fa-print"></i>
        )}
      </span>
    </button>
  );
};

export default EstatePrint;
